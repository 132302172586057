<template>
  <div class="wrap-item" :style="{ color: mainColor }">
    <div
      class="tag-group"
      v-if="currentKey === 3 && $route.path === '/m-dao/proposal'"
    >
      <div class="tag tag1 iconfont icon-gou" v-if="true"></div>
      <div class="tag tag2" v-else>×</div>
    </div>
    <div class="title">MEB TEAM</div>
    <div class="time-group">
      <div class="time">
        <span v-if="currentKey === 1">将结束于: </span>
        <span v-if="currentKey === 2">将开始于: </span>
        <span v-if="currentKey === 3">已结束于: </span>
        2022-03-08 12:12:11
      </div>
      <div
        class="check"
        @click="$gbUtils.handleToPage('/m-dao/proposalDetail')"
      >
        查看 <i class="right" :style="{ borderColor: mainColor }"></i>
      </div>
    </div>
    <div class="type-list">
      <div class="type-item" :style="{ borderColor: mainColor }">理事提案</div>
    </div>
    <VoteNumber
      v-if="currentKey === 3 && $route.path === '/m-dao/proposal'"
      style="margin-bottom: 20px"
      color="#C1C1C1"
      :favorNum="1"
      :opposeNum="2"
      :favorPercent="70"
    ></VoteNumber>
  </div>
</template>

<script>
import VoteNumber from './VoteNumber.vue'
const colorMap = new Map(
  [
    [1, '#000000'],
    [2, '#818086'],
    [3, '#C1C1C1']
  ]
)
export default {
  components: { VoteNumber },
  name: 'PropsalListItem',
  props: {
    currentKey: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      mainColor: '#000000'
    }
  },
  methods: {

  },
  mounted () {
  },
  watch: {
    currentKey (val) {
      this.mainColor = colorMap.get(+val)
    }
  }
}
</script>

<style scoped lang="scss">
.wrap-item {
  border-top: 1px solid #e3e3e3;
  .tag-group {
    float: right;
    .tag {
      width: 35px;
      height: 18px;
      color: #fff;
      border-radius: 0px 0px 5px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
    }
    .tag1 {
      background: #000;
    }
    .tag2 {
      background-color: #a2a2a2;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .time-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .time {
      span {
        font-weight: bold;
      }
    }
    .check {
      font-size: 15px;
      display: flex;
      align-items: center;
      i {
        margin-left: 2px;
        font-weight: bold;
        border: 1px solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 4px;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  .type-list {
    padding-top: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-bottom: 20px;
    .type-item {
      width: 66px;
      height: 25px;
      border: 1px solid #000000;
      line-height: 25px;
      text-align: center;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}
</style>
