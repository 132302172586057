<template>
  <div class="wrap">
    <div class="tab-list">
      <div
        class="tab-item"
        v-for="item in tabList"
        :key="item.key"
        :class="{ 'active-tab-item': currentKey === item.key }"
        @click="handleChangeTab(item.key)"
      >
        {{ item.name }}
      </div>
    </div>
    <PropsalListItem
      :currentKey="currentKey"
      v-for="item in 2"
      :key="item"
    ></PropsalListItem>
  </div>
</template>

<script>
import PropsalListItem from './PropsalListItem.vue'
export default {
  components: { PropsalListItem },
  name: 'PropsalList',
  props: {
    tabList: {
      type: Array,
      default: () => [],
      required: true
    } // key 1:进行中 2:即将开始 3:已结束
  },
  data () {
    return {
      currentKey: 1
    }
  },
  methods: {
    handleChangeTab (key) {
      this.currentKey = key
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.wrap {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  .tab-list {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .tab-item {
      padding-right: 30px;
      color: #a2a2a2;
      font-size: 17px;
    }
    .active-tab-item {
      color: #000000;
      font-weight: bold;
    }
  }
}
</style>
